import axios from 'axios';
import JsonApi from 'devour-client';

const instance = axios.create({
  headers: { Accept: 'application/vnd.api+json' },
});

const jsonApi = new JsonApi({ apiUrl: '/api' });

const errorMiddleware = {
  name: 'custom-error-middleware',
  error(payload) {
    if (jsonApi.axios.isCancel(payload)) {
      return { type: 'cancel' };
    }

    return jsonApi.applyErrorMiddleware(payload);
  },
};

const cancellableRequest = {
  name: 'axios-cancellable-request',
  req(payload) {
    return jsonApi.axios(Object.assign(payload.req, {
      cancelToken: new jsonApi.axios.CancelToken((c) => {
        jsonApi.cancel = c;
      }),
    }));
  },
};

jsonApi.replaceMiddleware('errors', errorMiddleware);
jsonApi.replaceMiddleware('axios-request', cancellableRequest);

jsonApi.define(
  'category',
  {
    slug: '',
    title: '',
    is_hidden: '',
    order: '',
    articles: {
      jsonApi: 'hasMany',
      type: 'article',
    },
  },
  {
    collectionPath: 'categories',
  },
);

jsonApi.define(
  'article',
  {
    title: '',
    slug: '',
    body: '',
    is_draft: '',
    order: '',
    category_id: '',
  },
  {
    collectionPath: 'articles',
  },
);

jsonApi.define(
  'cattery',
  {
    name: '',
    city: {
      country: { name: '' },
      name: '',
    },
    system: { name: '' },
    breeds: [],
    photo_src: '',
  },
  {
    collectionPath: 'catteries',
  },
);

jsonApi.define(
  'cat',
  {
    id: '',
    escaped_title_name: '',
    photo_src: '',
    breed: {
      jsonApi: 'hasOne',
      type: 'breeds',
    },
    holder_country: { code: '', name: '' },
    title: { name: '' },
    color: { code: '' },
    age: '',
  },
  {
    collectionPath: 'cats',
  },
);

jsonApi.define(
  'cat_show',
  {
    name: '',
    city: {
      jsonApi: 'hasOne',
      type: 'cities',
    },
    country: {
      jsonApi: 'hasOne',
      type: 'countries',
    },
    day: '',
    month: '',
    has_super_ring: '',
    has_mega_show: '',
    is_has_worldshow_badge: '',
    is_has_goldencat_badge: '',
    rating_coefficient_extended: '',
    photos: [],
    photo_albums: [],
  },
  {
    collectionPath: 'cat_shows',
  },
);

jsonApi.define(
  'country',
  {
    name: '',
  },
  {
    collectionPath: 'countries',
  },
);

jsonApi.define(
  'city',
  {
    name: '',
    region: {
      name: '',
    },
  },
  {
    collectionPath: 'cities',
  },
);

jsonApi.define(
  'breed',
  {
    shortcut: '',
    name: '',
  },
  {
    collectionPath: 'breeds',
  },
);

jsonApi.define(
  'kitten',
  {
    text: '',
    price: '',
    photo_src: '',
    cat: {
      title: { name: '' },
      breed: { shortcut: '' },
      color: { code: '' },
      age: '',
      gender: '',
    },
    currency: { shortcut: '' },
  },
  {
    collectionPath: 'kittens',
  },
);
jsonApi.define(
  'slide_photo',
  {
    path: '',
    name: '',
    id: '',
  },
  {
    collectionPath: 'slide_photos',
  },
);

export { instance, jsonApi };
