export const GENDERS = {
  '': 'Любой',
  'male': 'Кот',
  'female': 'Кошка',
  'neuter': 'Кастрат',
  'spay': 'Стерилизованная кошка',
  'litter': 'Помет',
};

export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const CITIES = {
  109: 'Пенза',
  124: 'Саранск',
};

export const ERROR_MESSAGE = (
  'Не удалось получить данные. Перезагрузите страницу и попробуйте снова.'
);

export const TOP_CAT_URL = 'https://top-cat.org';

export const SCROLL_OPTIONS = {
  vuescroll: {
    mode: 'native',
    sizeStrategy: 'percent',
    detectResize: true,
  },
  scrollPanel: {
    initialScrollY: 10,
    initialScrollX: 0,
    scrollingX: false,
    scrollingY: true,
    speed: 300,
    easing: undefined,
    verticalNativeBarPos: 'right',
  },
  rail: {
    background: '#000',
    opacity: 0.3,
    size: '4px',
    specifyBorderRadius: false,
    gutterOfEnds: '10px',
    gutterOfSide: '5px',
    keepShow: false,
  },
  bar: {
    showDelay: 500,
    onlyShowBarOnScroll: true,
    keepShow: true,
    background: '#905661',
    opacity: 1,
    hoverStyle: false,
    specifyBorderRadius: false,
    minSize: 0,
    size: '12px',
    disable: false,
  },
};

export const SAVE_ANALYSTIC_COOKIE = 'save_analytic_cookie';
