import { jsonApi } from './index';

const categoriesAPI = {
  get() {
    return jsonApi.findAll('category', {
      include: 'articles',
      filter: { is_hidden: false, with_articles: true },
    });
  },
};

export default categoriesAPI;
