import vocabulariesAPI from '@/api/vocabulary';
import { ERROR_MESSAGE } from '@/constants';

const ERROR = 'error';

export default {
  namespaced: true,

  actions: {
    async getVocabulary(__, params) {
      try {
        return (await vocabulariesAPI.getVocabulary(params)).data;
      } catch (errors) {
        this.$notify({
          type: ERROR,
          text: ERROR_MESSAGE,
        });
        return null;
      }
    },
  },
};
