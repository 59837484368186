<template>
  <base-layout>
    <template v-slot:sidebar class="information-sidebar">
      <section-sidebar name="Информация">
        <articles-list :categories="categories"
                        :selectedCategory="selectedCategory">
        </articles-list>
      </section-sidebar>
    </template>

    <template v-slot:content>
      <template v-if="selectedArticle">
        <section-title :title="selectedArticle.title" id="about">
        </section-title>

        <div class="article-text" v-html="selectedArticle.body"></div>

        <div class="articles-navigation">
          <div class="prev-link-wrapper">
            <router-link v-if="prevArticle"
                          class="navigation-link navigation-link__navigat navigation-link--prev"
                          :to="{ name: 'information',
                          params: { categorySlug: prevArticleCategory.slug,
                          articleSlug: prevArticle.slug }}">
              предыдущая
            </router-link>
          </div>
          <div class="next-link-wrapper">
            <router-link v-if="nextArticle"
                          class="navigation-link navigation-link__navigat"
                          :to="{ name: 'information',
                          params: { categorySlug: nextArticleCategory.slug,
                          articleSlug: nextArticle.slug }}">
              следующая
            </router-link>
          </div>
        </div>
      </template>
    </template>
  </base-layout>
</template>

<script>
import categoriesAPI from '@/api/category';
import ArticlesList from '@/components/ArticlesList/ArticlesList.vue';
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import SectionTitle from '@/components/SectionTitle/SectionTitle.vue';
import SectionSidebar from '@/components/SectionSidebar/SectionSidebar.vue';

export default {
  name: 'Information',

  data() {
    return {
      categories: [],
      selectedCategory: null,
      selectedArticle: null,
      prevArticle: null,
      nextArticle: null,
      prevArticleCategory: null,
      nextArticleCategory: null,
    };
  },

  components: {
    'articles-list': ArticlesList,
    'base-layout': BaseLayout,
    'section-title': SectionTitle,
    'section-sidebar': SectionSidebar,
  },

  watch: {
    $route: 'selectArticle',
  },

  async mounted() {
    this.categories = (await categoriesAPI.get()).data;

    if (!this.categories.length) {
      return;
    }

    const { categorySlug, articleSlug } = this.selectArticle();

    if (!categorySlug || !articleSlug) {
      this.$router.replace({
        name: 'information',
        params: {
          categorySlug: this.selectedCategory.slug,
          articleSlug: this.selectedArticle.slug,
        },
      });
    }
  },

  methods: {
    selectArticle() {
      const { categorySlug, articleSlug } = this.$route.params;

      this.prevArticle = null;
      this.nextArticle = null;

      // если в урле не указана категория, открывается первая
      this.selectedCategory = categorySlug
        ? this.selectedCategory = this.categories.find((c) => c.slug === categorySlug)
        : this.categories[0];

      const selectedCategoryArticles = this.selectedCategory.articles;

      // если в урле не указана статья, открывается первая в выбранной категории
      this.selectedArticle = articleSlug
        ? selectedCategoryArticles.find((a) => a.slug === articleSlug)
        : selectedCategoryArticles[0];

      // получаем предыдущую и следующую статью для ссылок навигации
      // навигация сквозная, для первой статьи в категории предыдущей будет последняя статья
      // предыдущей категории, для последней статьи в категории следующей будет первая статья
      // следующей категории
      const selectedArticleIndex = selectedCategoryArticles.indexOf(this.selectedArticle);
      let prevArticleIndex = selectedArticleIndex - 1;
      const nextArticleIndex = selectedArticleIndex + 1;
      const selectedCategoryIndex = this.categories.indexOf(this.selectedCategory);

      if (prevArticleIndex > -1) {
        this.prevArticle = selectedCategoryArticles[prevArticleIndex];
        this.prevArticleCategory = this.selectedCategory;
      } else if (selectedCategoryIndex > 0) {
        const prevCategoryArticles = this.categories[selectedCategoryIndex - 1].articles;
        prevArticleIndex = prevCategoryArticles.length - 1;
        this.prevArticle = prevCategoryArticles[prevArticleIndex];
        this.prevArticleCategory = this.categories[selectedCategoryIndex - 1];
      }

      if (nextArticleIndex < selectedCategoryArticles.length) {
        this.nextArticle = selectedCategoryArticles[nextArticleIndex];
        this.nextArticleCategory = this.selectedCategory;
      } else if (selectedCategoryIndex < this.categories.length - 1) {
        [this.nextArticle] = this.categories[selectedCategoryIndex + 1].articles;
        this.nextArticleCategory = this.categories[selectedCategoryIndex + 1];
      }

      return { categorySlug, articleSlug };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.route-information {
  width: 100%;

  .article-text {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  .articles-navigation {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .navigation-link--prev:after {
      left: 0;
      right: auto;
    }
  }
}

@media (min-width: 1281px) {
  .information-route .article-text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (min-width: 1600px) {
  .information-route {
    .app-content {
      padding-bottom: 80px;
    }
  }
}
</style>
