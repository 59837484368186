import Vue from 'vue';
import Vuex from 'vuex';
import createCache from 'vuex-cache';

import vocabulary from '@/modules/vocabulary';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    vocabulary,
  },
  plugins: [createCache()],
});
