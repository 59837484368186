<template>
  <div v-if="visible" class="scroll-top-btn-wrapper">
    <div class="scroll-top-btn" @click.prevent="click">
      Наверх
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollTopButton',

  data() {
    return {
      visible: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.scrollListener);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollListener);
  },

  methods: {
    click() {
      window.document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },

    scrollListener() {
      this.visible = window.scrollY > window.innerHeight;
    },
  },
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
