<template>
  <base-layout>
    <template v-slot:content>
      <section-title title="Правовая информация"/>
      <legal-docs-list/>
      <cookies-use-form/>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import CookiesUseForm from '@/components/CookiesUseForm/CookiesUseForm.vue';
import LegalDocsList from '@/components/LegalDocsList/LegalDocsList.vue';
import SectionTitle from '@/components/SectionTitle/SectionTitle.vue';

export default {
  name: 'LegalDocs',

  components: {
    'legal-docs-list': LegalDocsList,
    'base-layout': BaseLayout,
    'cookies-use-form': CookiesUseForm,
    'section-title': SectionTitle,
  },
};
</script>
