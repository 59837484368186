import { jsonApi } from './index';

const kittensAPI = {
  get(filter) {
    return jsonApi.findAll('kitten', {
      filter,
    });
  },
};

export default kittensAPI;
