import Vue from 'vue';
import Router from 'vue-router';

import CatShows from '@/views/CatShows/CatShows.vue';
import Catteries from '@/views/Catteries/Catteries.vue';
import Contacts from '@/views/Contacts/Contacts.vue';
import Home from '@/views/Home/Home.vue';
import Information from '@/views/Information/Information.vue';
import Kittens from '@/views/Kittens/Kittens.vue';
import LegalDocs from '@/views/LegalDocs/LegalDocs.vue';
import PageNotFound from '@/views/PageNotFound/PageNotFound.vue';
import Pets from '@/views/Pets/Pets.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },

    {
      path: '/information/categories/:categorySlug?/articles/:articleSlug?',
      alias: ['/information/categories/:categorySlug?', '/information'],
      name: 'information',
      component: Information,
    },

    {
      path: '/cat-shows',
      name: 'cat-shows',
      component: CatShows,
    },

    {
      path: '/kittens',
      name: 'kittens',
      component: Kittens,
    },

    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts,
    },

    {
      path: '/catteries',
      name: 'catteries',
      component: Catteries,
    },

    {
      path: '/pets',
      name: 'pets',
      component: Pets,
    },

    {
      path: '/legal-information',
      name: 'legal-information',
      component: LegalDocs,
    },

    {
      path: '*',
      name: 'page-not-found',
      component: PageNotFound,
    },
  ],
});

export default router;
