<template>
  <div>
    <div class="app-container app-background app-body">
      <div class="app-container app-container--bg">
        <div class="home-route">
          <div class="home-cover">
            <div v-if="images.length > 0" class="home-cover__slider">
              <hooper :wheelControl="false"
                      :centerMode="true"
                      :autoPlay="true"
                      style="height: 100%">
                <slide
                  class="home-cover__slide"
                  v-for="image in images"
                  :key="image.id">
                  <img :src="image.path" class="home-cover__img"/>
                </slide>
                <hooper-pagination slot="hooper-addons"></hooper-pagination>
              </hooper>
            </div>
            <template v-else>
              <img src="./images/home_cover.jpg" class="home-cover__img"/>
            </template>
            <div class="home-cover-text">
              <div class="home-cover-text__title">Элитар</div>
              <div class="home-cover-text__description">
                Общественная организация любителей животных
              </div>
            </div>
            <a href="#about" class="home-cover__arrow-down"></a>
          </div>

          <div class="app-container app-container--md">
            <div class="app-sidebar app-container--sm">
              <section-title title="Да здравствует Её Величество <mark>Кошка!</mark>">
              </section-title>
              <p>Добро пожаловать на сайт Элитар Клуба! Мы рады всем!</p>

              <p>
                На нашем сайте Вы сможете ознакомиться с животными клуба, питомниками,
                с ближайшими и прошедшими выставками, узнать полезную информацию про кошек.
              </p>

              <p>Если у Вас есть вопрос, Вы можете его задать в разделе контакты.</p>
            </div>
            <div class="app-content app-container--sm">
              <div class="about-article">
                <div class="about-article__top-block">
                  <div class="about-article__text-col">
                    <section-title title="О Клубе" id="about" isInlineDivider="true">
                    </section-title>

                    <p>
                      Общественная Организация Любителей Животных «Элитар-клуб» организована в 2001
                      году.
                    </p>

                    <p>
                      Наш клуб создан на общественных началах группой единомышленников во главе
                      с Рудаковой Анной Анатольевной весной 2001 года и в 2002 году был принят
                      прямым членом Всемирной организации кошек (WCF, штаб-квартира в Эссене,
                      Германия).
                    </p>

                    <p>
                      За годы работы из небольшого кружка любителей-фелинологов клуб превратился
                      в настоящую организацию, в составе которой:
                    </p>

                    <ul>
                      <li>Фелинологическое отделение (WCF)</li>
                      <li>Ветеринарный центр "Кот и пес" / диагностика и лечение животных</li>
                      <li>
                        Зоосалон "Кот и пес" / профессиональные корма, косметика, ветпрепараты
                        и аксессуары для животных.
                      </li>
                    </ul>
                  </div>

                  <div class="about-article__img-col">
                    <img src="./images/about_cover.jpg" class="about-article__img"/>
                  </div>
                </div>

                <div class="about-article__bottom-block app-container--sm">
                  <p>
                    В клубе можно получить консультации фелинологов по содержанию животных:
                    правильному кормлению и воспитанию, племенному разведению, выставочной
                    карьере животного, помощь в приобретении новых животных, побщаться с
                    единомышленниками, профессионалами и просто хорошими людьми.
                  </p>

                  <p>Мы любим и уважаем каждого члена нашего клуба и рады новым друзьям!</p>

                  <p>
                    Традиционные Международные выставки кошек в Пензе мы проводим два раза в год: в
                    марте и сентябре, а еще проводятся выездные выставки клуба в г. Саранск.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="app-container">
      <div class="app-container app-container--bg">
        <div class="app-container app-container--md">
          <div class="app-sidebar app-container--sm">
            <footer-contacts></footer-contacts>
          </div>
          <div class="app-content app-container--sm">
            <footer-partners></footer-partners>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle/SectionTitle.vue';
import FooterContacts from '@/components/Footer/FooterContacts/FooterContacts.vue';
import FooterPartners from '@/components/Footer/FooterPartners/FooterPartners.vue';

import slidePhotosAPI from '@/api/slide_photo';

import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'Home',

  data() {
    return {
      settings: {
        height: 100,
        centerMode: true,
        autoPlay: true,
      },
      images: [],
    };
  },

  components: {
    'section-title': SectionTitle,
    'footer-contacts': FooterContacts,
    'footer-partners': FooterPartners,
    Hooper,
    Slide,
    HooperPagination,
  },

  async mounted() {
    this.images = (await slidePhotosAPI.get()).data;
  },
};
</script>

<style lang="scss">
@import '~@/styles/constants.scss';
@import '~@/styles/palette.scss';

.hooper-indicator:hover,
.hooper-indicator.is-active {
  background-color: brown !important;
}

#about {
  margin-top: -$header-height;
  padding-top: $header-height;
}

.route-home {
  .app-sidebar {
    background: $brown;
    flex: 48.828125%;
    color: white;
  }

  .app-content {
    flex: 51.171875%;
    color: $dark-gray;
  }
}

.home-cover {
  width: 100%;
  max-width: 100%;
  height: auto;
  position: relative;

  &__slider {
    width: 100%;
    max-width: 100%;
    height: 100%;
    text-align: center;

    li:before {
      display: none;
    }
  }

  &__slide {
    width: 100% !important;

    li::before {
      content: none;
    }
  }

  &__img {
    max-height: 100%;
    object-fit: cover;
    max-width: 100%;
  }

  &__arrow-down {
    display: none;
    position: absolute;
    bottom: 60px;
    left: 50%;
    margin-left: -30px;
    width: 60px;
    height: 60px;
    background-image: url('./images/arrow_down.png');
  }
}

.home-cover-text {
  display: none;
  position: absolute;
  top: 50%;
  right: 50px;
  margin-top: -114px;
  text-align: center;
  text-transform: uppercase;

  &__title {
    color: #fff;
    font-family: 'Playfair Display';
    font-weight: bold;
    font-size: 105px;
    line-height: 134px;
    letter-spacing: 7px;
  }

  &__description {
    color: #fff;
    font-size: 19px;
    line-height: 24.5px;
  }
}

.app-background {
  background: $brown;
}

.home-route{
  .app-sidebar,
  .app-content {
    padding-top: 40px;
  }

  .app-content {
    padding-bottom: 0;
  }

  .app-sidebar {
    padding-bottom: 20px;
  }

  .about-article {
    padding-bottom: 20px;

    &__top-block{
      display: block;
    }

    &__img-col {
      padding-left: 0;
      padding-bottom: 25px;
    }

    &__img {
      max-width: 100%;
      max-height: 100%;
      min-width: 272px;
    }

    &__bottom-block {
      padding-left: 0;
    }
  }
}

footer {
  background: linear-gradient(to right, $brown 0%, $brown 50%, $footer-bg 50%, $footer-bg 100%);

  .app-sidebar {
    padding-top: 60px;
    padding-bottom: 24px;
  }

  .app-content {
    background: $footer-bg;
    padding: 40px 20px;
  }
}

@media (min-width: 1024px) {
  .home-cover {
    &__arrow-down {
      display: block;
    }
  }

  .home-cover-text {
    display: block;
  }

  .app-background {
    background: linear-gradient(to right, $brown 0%, $brown 50%, white 50%, white 100%);
  }

  .home-route {
    .app-sidebar,
    .app-content {
      padding-top: 60px;
    }

    .app-content {
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 60px;
    }

    .about-article__img {
      min-width: 390px;
    }
  }

  .about-article {
    padding-bottom: 0;
  }

  footer {
    display: flex;

    .app-content {
      padding: 80px 30px 80px 60px;
    }
  }
}

@media (min-width: 1281px) {
  .route-home {
    .app-sidebar {
      flex: 40.69767441860465%;
    }

    .app-content {
      flex: 59.30232558139535%;
    }
  }

  .home-cover-text {
    right: 80px;
    margin-top: -161px;

    &__title {
      font-size: 150px;
      line-height: 190px;
      letter-spacing: 10px;
    }

    &__description {
      font-size: 28px;
      line-height: 35px;
    }
  }
}

@media (min-width: 1492px) {
  .home-route .about-article {
    &__top-block{
      display: flex;
    }

    &__img-col {
      flex: 0 2 472px;
      padding-left: 60px;
      padding-bottom: 75px;
    }

    &__text-col {
      flex: 0 1 328px;
    }
  }
}

@media (min-width: 1600px) {
  .home-route {
    .about-article__img-col {
      padding-left: 80px;
    }

    .app-content {
      padding-left: 80px;
    }
  }

  footer .app-content {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (min-width: 1780px) {
  .home-route .about-article__img-col {
    flex-shrink: 0;
  }
}
</style>
