<template>
  <div class="articles-list">
    <ul>
      <li class="category"
          v-for="category in categories"
          :key="category.id"
          :class="{'active': category == selectedCategory}">
        <router-link :to="{ name: 'information', params: { categorySlug: category.slug,
          articleSlug: category.articles[0].slug }}"
                     @click.native="selectedCategory = category">
          {{category.title}}
        </router-link>
        <ul class="articles" v-if="category.articles">
          <li class="article" v-for="article in category.articles" :key="article.id">
            <router-link :to="{ name: 'information', params: { categorySlug: category.slug,
              articleSlug: article.slug }}">
              {{article.title}}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ArticlesList',

  props: {
    categories: Array,
    selectedCategory: Object,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.articles-list {
  padding: 8px 20px;

  ul {
    list-style: none;
    padding-left: 0;
    margin: 32px 0 32px;
  }

  li {
    margin-bottom: 12px;
    display: flex;
    align-items: baseline;

    &:before {
      content: " ";
      margin-right: 16px;
      width: 6px;
      height: 6px;
      background: $red;
      display: inline-block;
      transform: rotate(45deg);
      flex-shrink: 0;
    }
  }

  li {
    display: block;

    &:before{
      content: none;
    }
  }

  a {
    text-decoration: none;
    color: white;
    position: relative;
    display: block;

    &:hover {
      color: $light-red;
    }
  }

  .category,
  .article {
    font-size: 16px;
    line-height: 23px;
  }

  .category {
    font-weight: normal;
    text-transform: uppercase;

    &.active .articles {
      display: block;
    }
  }

  .articles {
    display: none;
    position: relative;
    margin-top: 30px;

    &:before {
      content: '';
      position: absolute;
      left: 5px;
      top: 16px;
      bottom: 20px;
      display: inline-block;
      width: 2px;
      background: $red;
    }

    &:after {
      content: '';
      width: 9px;
      height: 9px;
      background: $red;
      transform: rotate(45deg);
      display: inline-block;
      position: absolute;
      left: 1px;
      bottom: 11px;
    }
  }

  .article {
    font-weight: 300;
    text-transform: none;
    padding-left: 44px;
    position: relative;
    margin-bottom: 20px;

    .router-link-active {
      color: $red;
      font-weight: normal;

      &:after {
        content: '';
        position: absolute;
        right: -88px;
        top: 6px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid $brown;
      }
    }
  }
}

@media (min-width: 1024px) {
  .articles-list {
    margin-top: 60px;

    .category,
    .article {
      font-size: 20px;
      line-height: 29px;
    }

    .articles {
      margin-top: 24px;
    }

    .article {
      margin-bottom: 16px;
      padding-left: 60px;
    }
  }
}

@media (min-width: 1600px) {
  .articles-list .article .router-link-active:after {
    right:-108px;
  }
}
</style>
