import Vue from 'vue';
import VueCookies from 'vue-cookies';
import vueDebounce from 'vue-debounce';
import InfiniteLoading from 'vue-infinite-loading';
import vuescroll from 'vuescroll';
import Notifications from 'vue-notification';
import Spinner from '@/components/Spinner/Spinner.vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './styles/index.scss';

Vue.config.productionTip = false;
Vue.prototype.$globalBus = new Vue();

Vue.use(VueCookies);

Vue.use(vueDebounce, {
  defaultTime: '400ms',
});

Vue.use(vueDebounce, {
  defaultTime: '400ms',
});

Vue.use(InfiniteLoading, {
  slots: {
    noResults: '',
    noMore: '',
    spinner: {
      render: (h) => h(Spinner),
    },
  },
});

Vue.use(Notifications);

Vue.use(vuescroll);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
