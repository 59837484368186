import petsAPI from '@/api/pet';
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import CatsList from '@/components/CatsList/CatsList.vue';
import GenderFilter from '@/components/GenderFilter/GenderFilter.vue';
import FiltersLiveSearch from '@/components/FiltersLiveSearch/FiltersLiveSearch.vue';
import Spinner from '@/components/Spinner/Spinner.vue';
import SectionSidebar from '@/components/SectionSidebar/SectionSidebar.vue';
import SectionTitle from '@/components/SectionTitle/SectionTitle.vue';
import NotFound from '@/components/NotFound/NotFound.vue';
import { ERROR_MESSAGE } from '@/constants';
import { jsonApi } from '@/api/index';

export default {
  name: 'Pets',

  components: {
    'base-layout': BaseLayout,
    'cats-list': CatsList,
    'gender-filter': GenderFilter,
    'filters-live-search': FiltersLiveSearch,
    'section-sidebar': SectionSidebar,
    'section-title': SectionTitle,
    'not-found': NotFound,
    Spinner,
  },

  data() {
    const queryParams = this.$route.query;
    const breeding = 'breeding';
    const defaultFilters = { page: 1 };
    const filtersMenu = {
      breeding: 'Участвуют в племенной работе',
      not_breeding: 'Не участвуют в племенной работе',
    };

    return {
      filtersMenu,
      cats: [],
      total: 0,
      isLoading: false,
      title: filtersMenu[breeding],
      filters: { status: breeding, ...defaultFilters, ...queryParams },
      defaultFilters,
      infiniteId: 1,
      breeding,
    };
  },

  watch: {
    $route: 'setFilters',
  },

  async mounted() {
    await this.loadCats();

    this.$refs.breedFilter.search();
  },

  methods: {
    resetFilters() {
      this.filters = { status: this.filters.status, ...this.defaultFilters };
      this.reloadCats();
      this.$globalBus.$emit('resetFilters');
    },

    applyFilters() {
      this.filters.page = 1;
      this.reloadCats();
    },

    reloadCats() {
      this.cats = [];
      this.total = 0;
      this.infiniteId += 1;

      this.$router.push({ path: 'pets', query: { ...this.filters, t: new Date().getTime() } });

      this.loadCats();
    },

    async loadCats($state) {
      let cats = [];
      let total = 0;

      if (this.isLoading) {
        jsonApi.cancel();
      }

      if (this.cats.length && this.cats.length === this.total) {
        if ($state) {
          $state.complete();
        }
        return;
      }

      this.isLoading = true;

      try {
        ({ data: cats, meta: { total } } = await petsAPI.get(this.filters));
      } catch (errors) {
        if (errors.type !== 'cancel') {
          this.$notify({
            type: 'error',
            title: ERROR_MESSAGE,
          });

          this.isLoading = false;
        }

        return;
      }

      this.isLoading = false;
      this.total = total;
      this.filters.page = parseInt(this.filters.page) + 1;
      this.cats = this.cats.concat(cats);

      if ($state) {
        this.cats.length === total ? $state.complete() : $state.loaded();
      }
    },

    setFilters() {
      let { status } = this.$route.query;
      if (this.filters.status !== status) {
        status = status || this.breeding;
        this.filters.status = status;
        this.title = this.filtersMenu[status];
        this.resetFilters();
      } else {
        this.filters = this.$route.query;
      }
    },
  },
};
