<template>
  <base-layout>
    <template v-slot:sidebar>
      <div class="catteries">
        <section-title title="Хотите завести себе <mark>котенка?</mark>">
        </section-title>
        <div class="catteries__text">
          <p>
            Если Вы только хотите завести себе котенка, то, вероятно, уже
            задумались над тем, где, в кaком питомнике кошек его покупать.
          </p>
          <p>
            В питомнике кошек Вы можете приобрести котенка
            понравившейся Вам породы, созвонившись с заводчиком и узнав
            нaличие котят. Предварительно рекомендуется заглянуть на сайт
            питомника, где вся информация о породе и наличии котят доступна
            и регулярно обновляется.
          </p>
          <p>
            Обрaщаем Ваше внимание, что безопаснее всего котенка покупать
            именно в питомнике кошек, у заводчика, а не на Птичьих рынках.
            Заводчик кошек несет ответственность за здоровье продaваемых
            котят, это дает хоть какую-то уверенность, что приобретенный
            котенок не болен, не заглистован и получен от родителей той
            породы, которую Вы хотите иметь у себя дома.
          </p>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <template v-if="catteries.length">
        <catteries-list :catteries="catteries"/>
        <infinite-loading @infinite="getCatteries"></infinite-loading>
      </template>
      <spinner v-else-if="isLoading"/>
      <not-found v-else/>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import CatteriesList from '@/components/CatteriesList/CatteriesList.vue';
import catteriesAPI from '@/api/cattery';
import SectionTitle from '@/components/SectionTitle/SectionTitle.vue';
import Spinner from '@/components/Spinner/Spinner.vue';
import NotFound from '@/components/NotFound/NotFound.vue';
import { ERROR_MESSAGE } from '@/constants';

export default {
  name: 'Catteries',

  components: {
    'base-layout': BaseLayout,
    'section-title': SectionTitle,
    'not-found': NotFound,
    'catteries-list': CatteriesList,
    Spinner,
  },

  data() {
    return {
      catteries: [],
      isLoading: false,
      page: 1,
    };
  },

  mounted() {
    this.getCatteries();
  },

  methods: {
    async getCatteries($state) {
      let catteries = [];
      let total = 0;
      this.isLoading = true;

      try {
        ({ data: catteries, meta: { total } } = await catteriesAPI.get(this.page));
      } catch (errors) {
        this.$notify({
          type: 'error',
          title: ERROR_MESSAGE,
        });
        return;
      }

      this.isLoading = false;

      if (catteries.length) {
        this.page += 1;
        this.catteries = this.catteries.concat(catteries);
      }

      if (!$state) {
        return;
      }

      this.catteries.length === total ? $state.complete() : $state.loaded();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';
@import '~@/styles/table.scss';

.catteries {
  position: relative;
  height: 100%;
  flex: 1 0 auto;
  margin: 40px 0;
  color: white;

  &__text {
    max-width: 481px;
  }
}

@media (min-width: 480px) {
  .catteries{
    margin-top: 32px;
  }
}

@media (min-width: 1024px) {
  .catteries {
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 0;

    &__text {
      max-width: 380px;
    }
  }
}
</style>
