<template>
  <a class="show-photos-link"
        :href="`${mainTopCatUrl}${albumUrl}`"
        target="_blank">
    <div class="link-text">
      Посмотреть фотоотчет
    </div>
    <div class="show-photos"
         v-for="(photo, index) in showPhotos"
        :key="index"
        >
        <img :src="photo.thumb_src"/>
    </div>
  </a>
</template>

<script>
import { TOP_CAT_URL } from '@/constants';

export default {
  name: 'ShowPhotos',

  props: ['showPhotos', 'albumUrl'],

  data() {
    return {
      mainTopCatUrl: TOP_CAT_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.show-photos-link {
  overflow: hidden;
  max-height: 290px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.link-text {
  font-size: 16px;
  font-style: normal;
  color: $red;
  width: 100%;
  margin-bottom: 11px;
}

.show-photos {
  min-width: 118px;
  height: 78px;
  margin: 0 8px 8px 0;
  flex-basis: 23%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 480px) {
  .show-photos-link {
    max-height: 200px;
  }

  .show-photos {
    flex-basis: 46%;
  }
}

@media (min-width: 600px) {
  .show-photos-link {
    max-height: 290px;
  }

  .show-photos {
    flex-basis: 23%;
  }
}

@media (min-width: 1024px) {
  .show-photos {
    min-width: 189px;
    height: 126px;
    margin: 0 8px 8px 0;
    flex-basis: 23%;

    img {
      max-width: 100%;
    }
  }

  .show-photos-link {
    max-height: 290px;
  }
}

@media (max-width: 480px) {
  .show-photos-link {
    max-height: 290px;
    justify-content: space-around;
  }
}

</style>
