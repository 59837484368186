<template>
  <div class="footer-contacts">
    <router-link :to="{name: 'contacts'}" class="footer-title footer-link">
      Контакты
    </router-link>
    <router-link :to="{name: 'legal-information'}" class="footer-title footer-link">
      Правовая информация
    </router-link>
    <div class="footer-social-wrapper">
      <div class="footer-title">Мы в соцсетях:</div>
      <!--<a href="https://www.facebook.com/groups/617950278218168/" target="_blank" class="footer-social-logo footer-social-logo--fb" rel="nofollow"></a>-->
        <a href="https://vk.com/elitar_club" target="_blank" class="footer-social-logo footer-social-logo--vk" rel="nofollow"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterContacts',
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.footer-contacts {
  .footer-title {
    display: inline-block;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    color: white;
  }

  .footer-link {
    text-decoration: none;
    display: block;
    margin-bottom: 15px;

    &:hover {
      color: $light-red;
    }

    &:active {
      color: $red;
    }

    &.router-link-active {
      color: $red;
      font-weight: normal;
    }
  }

  .footer-social-wrapper {
    display: flex;
    align-items: center;
  }

  .footer-social-logo {
    width: 32px;
    height: 32px;
    margin-left: 16px;
    background-image: url('./images/elitar_social.png');
    background-size: 368px 32px;

    &--fb {
      background-position: 0 0;

      &:hover {
        background-position: 272px 0;
      }

      &:active {
        background-position: 176px 0;
      }
    }

    &--vk {
      background-position: 320px 0;

      &:hover {
        background-position: 224px 0;
      }

      &:active {
        background-position: 128px 0;
      }
    }
  }
}

@media (min-width: 1281px) {
  .footer-contacts {
    .footer-title {
      font-size: 20px;
      line-height: 29px;
    }
  }
}
</style>
