import { render, staticRenderFns } from "./FooterPartners.vue?vue&type=template&id=27283441&scoped=true&"
import script from "./FooterPartners.vue?vue&type=script&lang=js&"
export * from "./FooterPartners.vue?vue&type=script&lang=js&"
import style0 from "./FooterPartners.vue?vue&type=style&index=0&id=27283441&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27283441",
  null
  
)

export default component.exports