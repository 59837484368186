<template>
  <div :class="['main-menu',
               {'main-menu--opened': isOpened}]">
    <div class="main-menu__list">
      <router-link :to="{ name: 'home' }" class="main-menu__item main-menu__home-link" exact>
        Элитар клуб
      </router-link>

      <router-link :to="{ name: 'cat-shows' }"
                   class="main-menu__item">
        Выставки
      </router-link>

      <router-link :to="{ name: 'kittens' }"
                   class="main-menu__item">
        Котята
      </router-link>

      <router-link :to="{ name: 'pets' }"
                   class="main-menu__item">
        Питомцы
      </router-link>

      <!--<router-link :to="{ path: '/information' }"
                   class="main-menu__item">
        Информация
      </router-link>
      -->
      <router-link :to="{ name: 'catteries' }"
                   class="main-menu__item main-menu__mobile-link">
        Питомники
      </router-link>
    </div>

    <i :class="['main-menu__icon',
               {'main-menu__icon--active': isOpened}]"
       @click="toggleMenu"></i>
  </div>
</template>

<script>
export default {
  name: 'MainMenu',

  data() {
    return {
      isOpened: false,
    };
  },

  watch: {
    $route() {
      if (this.isOpened) {
        this.isOpened = false;
        this.$globalBus.$emit('menuWasOpened', this.isOpened);
      }
    },
  },

  created() {
    this.$globalBus.$on('sectionMenuWasOpened', (isSectionMenuOpened) => {
      if (isSectionMenuOpened && this.isOpened) {
        this.isOpened = false;
      }
    });
  },

  methods: {
    toggleMenu() {
      this.isOpened = !this.isOpened;
      this.$globalBus.$emit('menuWasOpened', this.isOpened);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';
@import '~@/styles/constants.scss';

header.#{$header-fixed-class} {
  .main-menu--opened .main-menu__list {
    top: $mobile-fixed-header-height;
  }
}

.main-menu {
  color: black;

  &__list {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  &--opened &__list {
    position: fixed;
    top: $mobile-fixed-header-height;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 40px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 10;
  }

  &--opened &__item {
    display: block;
    margin-bottom: 40px;
  }

  &__item {
    display: none;
    color: black;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      color: $red;
    }

    &.router-link-active {
      color: $red;
      font-weight: bold;
    }
  }

  &__icon {
    position: absolute;
    bottom: 24px;
    right: 20px;
    display: inline-block;
    background-image: url('./images/menu.png');
    background-size: 218px 22px;
    background-position: 156px 0;
    width: 32px;
    height: 22px;
    cursor: pointer;

    &:hover {
      background-position: 94px 0;
    }

    &--active {
      background-position: 32px 0;
    }
  }

  &.router-link-active {
    color: $red;
  }
}

.route-home header:not(.#{$header-fixed-class}) .main-menu__icon {
  bottom: 32px;
}

.route-home .main-menu--opened .main-menu__list {
  top: $mobile-header-height;
}

@media (max-width: 1023px) {
  .main-menu:not(.main-menu--opened) .main-menu__mobile-link.router-link-active {
    display: inline-block;
    border-bottom: 4px solid $red;
    padding-bottom: 19px;
    position: absolute;
    left: 24px;
    bottom: 0;
  }

  header.#{$header-fixed-class} .main-menu:not(.main-menu--opened)
  .main-menu__home-link.router-link-active {
    display: inline-block;
    border-bottom: 4px solid $red;
    padding-bottom: 19px;
    position: absolute;
    left: 24px;
    bottom: 0;
  }
}

@media (min-width: 1024px) {
  .route-home .main-menu--opened .main-menu__list {
    top: 0;
  }

  header.#{$header-fixed-class} {
    .main-menu--opened .main-menu__list {
      top: 0;
    }
  }

  .main-menu:not(.main-menu--opened) .main-menu__item.main-menu__home-link.router-link-active {
    display: none;
  }

  .main-menu:not(.main-menu--opened) {
    .main-menu__item:not(.main-menu__home-link).router-link-active {
      position: relative;
      padding-bottom: 30px;
      left: 0;
    }
  }

  .main-menu {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;

    &__home-link {
      display: none;
    }

    &__list {
      justify-content: flex-start;
      margin-right: 40px;
      top: 0;
    }

    &--opened {
      left: 0;
      position: fixed;
      top: 0;
      right: 0;
      height: 180px;
      background: white;
      padding: 0 60px 0 30px;
      box-sizing: border-box;
    }

    &--opened &__list {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      padding-top: 0;
      background: inherit;
      flex-direction: row;
      justify-content: flex-end;
    }

    &--opened &__item {
      display: inline-block;
      margin-bottom: 0;

      &.router-link-active {
        margin-right: 60px;
      }
    }

    &__item {
      margin-right: 60px;
      font-size: 20px;
      padding-bottom: 30px;
      border-bottom: 4px solid transparent;

      &.router-link-active {
        margin-right: 0;
        display: inline-block;
        border-color: $red;
      }

      &.main-menu__home-link {
        display: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__icon {
      position: relative;
      top: 0;
      right: 0;
      background-position: 0 0;
      margin-bottom: 40px;

      &--active {
        background-position: 32px 0;
      }
    }
  }
}

@media (min-width: 1281px) {
  .main-menu--opened {
    padding: 0 90px;
  }
}

@media (min-width: 1537px) {
  .main-menu {
    justify-content: flex-start;

    &__list {
      width: 100%;
      display: flex;
      margin-right: 0;
    }

    &__item:not(.main-menu__home-link) {
      display: inline-block;

      &:not(:last-child).router-link-active {
        margin-right: 60px;
      }
    }

    &__icon {
      display: none;
    }
  }
}

</style>
