import { jsonApi } from './index';

const catteriesAPI = {
  get(page) {
    return jsonApi.findAll('cattery', {
      filter: { page },
    });
  },
};

export default catteriesAPI;
