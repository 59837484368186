<template>
  <base-layout>
    <template v-slot:sidebar class="cat-shows-sidebar">
      <section-sidebar name="Выставки">
        <div class="filters">
          <div class="filters-menu">
            <router-link :to="{ query: '' }"
                         :class="['filters-menu__item',
                         {'filters-menu__item--active': filters.period === periodUpcoming}]">
              Предстоящие
            </router-link>
            <router-link :to="{ query: { period: 'past' } }"
                         :class="['filters-menu__item',
                         {'filters-menu__item--active': filters.period === periodPast}]">
              Прошедшие
            </router-link>
          </div>
          <div class="filters-field-group">
            <label>Год</label>
            <filters-select :options="years"
                            :initialValue="filters.year"
                            @input="filters.year = $event">
            </filters-select>
          </div>
          <div class="filters-field-group">
            <label>Месяц</label>
            <filters-select :options="months"
                            :initialValue="months[filters.month]"
                            @input="onChangeMonth">
            </filters-select>
          </div>
          <div class="filters-field-group">
            <label>Город</label>
            <filters-select :options="citiesValues"
                            :initialValue="cities[filters.city_id] || citiesValues[0]"
                            @input="onChangeCities">
            </filters-select>
          </div>
          <div class="filters-button-bar">
            <button class="filters-btn filters-btn--reset" @click="resetFilters">Сбросить</button>
            <button class="filters-btn filters-btn--search" @click="applyFilters">Найти</button>
          </div>
        </div>
      </section-sidebar>
    </template>

    <template v-slot:content>
      <div class="table cat-shows-table" v-if="catShows.length">
        <div class="table-row table-header">
          <div class="table-cell name">
            <span class="name">Название</span>
            <span class="city">/Город</span>
            <span class="date">/Дата</span>
          </div>
          <div class="table-cell date">Дата</div>
          <div class="table-cell city">Город</div>
        </div>
        <div class="table-row year">
          <div class="table-cell">
            {{catShowsYear}}
          </div>
        </div>
        <div class="table-row"
           v-for="catShow in catShows"
           :key="catShow.id">
          <a class="table-cell name"
               :href="`${mainTopCatUrl}/cat-shows/${catShow.id}`"
               target="_blank">
            <div class="name">{{catShow.name}}</div>
            <div class="badges">
              <span v-if="catShow.has_super_ring"
                    class="badge super-ring"
                    v-b-tooltip.hover.bottomright="{ customClass: 'cat-show-tooltip' }"
                    title="Super Ring - доп.номинация">
                SR
              </span>
              <span v-if="catShow.has_mega_show"
                    class="badge mega-show"
                    v-b-tooltip.hover.bottomright="{ customClass: 'cat-show-tooltip' }"
                    title="Mega Show - доп.номинация">
                MS
              </span>
              <span v-if="catShow.is_has_worldshow_badge"
                    class="badge world-show"
                    v-b-tooltip.hover.bottomright="{ customClass: 'cat-show-tooltip' }"
                    title="Всемирная выставка">
                WS
              </span>
              <a v-if="catShow.is_has_goldencat_badge"
                target="_blank"
                href="http://golden-cat.pro">
                <span class="badge golden-cat"
                      v-b-tooltip.hover.bottomright="{ customClass: 'cat-show-tooltip' }"
                      title="Золотая кошка">
                  GC
                </span>
              </a>
              <span v-if="catShow.rating_coefficient_extended > 1" class="badge x2">
                  x{{catShow.rating_coefficient_extended}}
              </span>
            </div>
          </a>
          <a class="table-cell date"
               :href="`${mainTopCatUrl}/cat-shows/${catShow.id}`"
               target="_blank">
            {{catShow.day}} {{catShow.month}}
          </a>
          <a class="table-cell city"
               :href="`${mainTopCatUrl}/cat-shows/${catShow.id}`"
               target="_blank">
            {{catShow.city.name}}, {{catShow.country.name}}
          </a>
          <div
            class="table-cell photos"
            v-if="catShow.photos.length && catShow.photo_albums.length"
          >
            <show-photos :showPhotos="catShow.photos" :albumUrl="catShow.photo_albums[0]"/>
          </div>
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="loadCatShows"></infinite-loading>
      </div>
      <spinner v-else-if="isLoading"/>
      <not-found v-else/>
    </template>
  </base-layout>
</template>

<script>
import Vue from 'vue';
import { VBTooltip } from 'bootstrap-vue';
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import FiltersSelect from '@/components/FiltersSelect/FiltersSelect.vue';
import SectionSidebar from '@/components/SectionSidebar/SectionSidebar.vue';
import ShowPhotos from '@/components/ShowPhotos/ShowPhotos.vue';
import Spinner from '@/components/Spinner/Spinner.vue';
import NotFound from '@/components/NotFound/NotFound.vue';
import catShowsAPI from '@/api/cat_show';
import {
  MONTHS,
  ERROR_MESSAGE,
  TOP_CAT_URL,
  CITIES,
} from '@/constants';
import { jsonApi } from '@/api/index';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.directive('b-tooltip', VBTooltip);

export default {
  name: 'CatShows',

  data() {
    const queryParams = this.$route.query;

    const firstCatShowYear = 2010;
    const periodUpcoming = 'upcoming';
    const periodPast = 'past';

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const pastYears = (
      Array(currentYear - firstCatShowYear + 1).fill().map((_, idx) => firstCatShowYear + idx)
        .reverse()
    );

    const upcomingYears = [currentYear];
    const defaultFilters = { page: 1, year: currentYear };

    return {
      catShows: [],
      total: 0,
      isLoading: false,
      pastYears,
      upcomingYears,
      periodUpcoming,
      periodPast,
      filters: { period: periodUpcoming, ...defaultFilters, ...queryParams },
      defaultFilters,
      infiniteId: 1,
      months: ['Любой'].concat(MONTHS),
      cities: CITIES,
      citiesValues: ['Любой'].concat(Object.values(CITIES)),
      catShowsYear: queryParams.year || currentYear,
      mainTopCatUrl: TOP_CAT_URL,
    };
  },

  async mounted() {
    await this.loadCatShows();

    this.getMaxYear();
  },

  components: {
    'base-layout': BaseLayout,
    'filters-select': FiltersSelect,
    'section-sidebar': SectionSidebar,
    'show-photos': ShowPhotos,
    'not-found': NotFound,
    Spinner,
  },

  watch: {
    $route: 'setFilters',
  },

  computed: {
    years() {
      return this.filters.period === this.periodUpcoming ? this.upcomingYears : this.pastYears;
    },
  },

  methods: {
    setFilters() {
      const { period } = this.$route.query;

      if (this.filters.period !== period) {
        this.filters.period = period || this.periodUpcoming;
        this.resetFilters();
      } else {
        this.filters = this.$route.query;
      }
    },

    async getMaxYear() {
      let maxYear;

      try {
        (maxYear = parseInt((await catShowsAPI.getMaxYear()).data));
      } catch (errors) {
        this.$notify({
          type: 'error',
          title: ERROR_MESSAGE,
        });
        return;
      }

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      this.upcomingYears = (
        Array(maxYear - currentYear + 1).fill().map((_, idx) => currentYear + idx)
      );
    },

    async loadCatShows($state) {
      let catShows = [];
      let total = 0;

      if (this.isLoading) {
        jsonApi.cancel();
      }

      if (this.catShows.length && this.catShows.length === this.total) {
        if ($state) {
          $state.complete();
        }
        return;
      }

      this.isLoading = true;

      try {
        ({ data: catShows, meta: { total } } = await catShowsAPI.get(this.filters));
      } catch (errors) {
        if (errors.type !== 'cancel') {
          this.$notify({
            type: 'error',
            title: ERROR_MESSAGE,
          });

          this.isLoading = false;
        }

        return;
      }

      this.isLoading = false;
      this.total = total;

      this.filters.page = parseInt(this.filters.page) + 1;
      this.catShows = this.catShows.concat(catShows);
      this.catShowsYear = this.filters.year;

      if ($state) {
        this.catShows.length === total ? $state.complete() : $state.loaded();
      }
    },

    applyFilters() {
      this.filters.page = 1;
      this.reloadCatShows();
    },

    resetFilters() {
      this.filters = { period: this.filters.period, ...this.defaultFilters };
      this.reloadCatShows();
      this.$globalBus.$emit('resetFilters');
    },

    reloadCatShows() {
      this.infiniteId += 1;
      this.catShows = [];
      this.total = 0;

      this.$router.push({ path: 'cat-shows', query: { ...this.filters, t: new Date().getTime() } });

      this.loadCatShows();
    },

    onChangeMonth(monthName) {
      const monthIndex = this.months.indexOf(monthName);

      if (monthIndex > 0) {
        this.filters.month = monthIndex;
        return;
      }

      if (monthIndex === 0) {
        delete this.filters.month;
      }
    },

    onChangeCities(cityName) {
      const valueIndex = Object.values(this.cities).indexOf(cityName);
      const key = Object.keys(this.cities)[valueIndex];

      if (key !== '') {
        this.filters.city_id = key;
      } else {
        delete this.filters.city_id;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/palette.scss';
@import '~@/styles/table.scss';
@import '~@/styles/filters.scss';

.cat-shows-sidebar {
  .filters-field__item {
    .region {
      font-size: 11px;
      line-height: 12px;
      color: rgba(255,255,255, 0.6);
    }

    &:not(.filters-field__item--selected):hover .region {
      color: rgba(250, 82, 82, 0.6);
    }
  }
}

.cat-shows-table {
  margin-top: 32px;

  .table-header {
    .table-cell.date,
    .table-cell.city {
      display: none;
    }
  }

  .table-row {
    justify-content: flex-start;
    color: #000;

    a {
      text-decoration: none;
      color: #000;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }

  .table-row.year {
    border-color: transparent;

    .table-cell {
      padding-top: 13px;
      background: #F4D9D9;
      color: $red;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .table-cell.photos {
    flex-basis: 100%;
  }

  .table-cell.name {
    flex-basis: 100%;
  }

  .table-row:not(.table-header) .table-cell.name,
  .table-row:not(.table-header) .table-cell.city,
  .table-row:not(.table-header) .table-cell.date {
    padding-top: 16px;
    padding-bottom: 0;
  }

  .table-row:not(.table-header) {
    padding-bottom: 10px;
  }

  .table-cell.city,
  .table-cell.date {
    flex: none;
  }

  .table-cell.city {
    padding-left: 0;
  }

  .table-row:not(.table-header) .table-cell.date {
    color: $brown;
  }

  .badges a {
    text-decoration: none;
  }

  .badge {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    border-radius: 10px;
    padding: 0 8px;
    color: white;
    margin-right: 8px;
    margin-top: 6px;
    display: inline-block;

    &.super-ring {
      background: #F1584D;
    }

    &.mega-show {
      background: #289BBF;
    }

    &.world-show {
      background: #3175B5;
    }

    &.golden-cat {
      background: #D4AF37;
    }

    &.x2 {
      color: #FEFF48;
      background: #015FC4;
    }
  }
}

.cat-show-tooltip {
  .tooltip-inner {
    background-color: $red;
    border-radius: 0;
  }

  .arrow::before {
    border-bottom-color: $red;
  }
}

@media (min-width: 480px) {
  .cat-shows-table {
    .table-header {
      .table-cell.name {
        .date {
          display: none;
        }
      }
    }

    .table-header .table-cell.date {
      display: block;
    }

    .table-cell.name,
    .table-cell.city {
      flex-basis: 72%
    }

    .table-row:not(.table-header) .table-cell.date {
      padding-top: 16px;
    }

    table-row:not(.table-header) .table-cell.photos {
      padding: 10px 0 0 0;
    }

    .table-cell.date {
      flex-basis: 28%;
    }

    .table-cell.city {
      padding-left: 16px;
    }
  }
}

@media (min-width: 600px) {
  .cat-shows-table {
    .table-header {
      .table-cell.name {
        .city {
          display: none;
        }
      }
    }

    .table-header .table-cell.city {
      display: block;
    }

    .table-cell.name {
      flex-basis: 50%;
    }

    .table-cell.city,
    .table-cell.date {
      flex-basis: 25%;
    }

    .table-row:not(.table-header) .table-cell.city {
      padding-top: 10px;
    }

    table-row:not(.table-header) .table-cell.photos {
      padding-top: 12px;
    }
  }
}

@media (min-width: 800px) {
  .table-row:not(.table-header) .table-cell.city {
    padding-top: 16px;
  }
}

@media (min-width: 1024px) {
  .cat-shows-table {
    margin-top: 60px;
    margin-bottom: 60px;

    .table-header {
      .table-cell.name {
        .city {
          display: inline;
        }
      }

      .table-cell.city {
        display: none;
      }
    }

    .table-row.year .table-cell {
      padding: 16px 20px 13px;
    }

    .table-row:not(.table-header) .table-cell.photos {
      padding-top: 16px;
      margin-bottom: 22px;
    }

    .table-row:not(.table-header) .table-cell.city {
      padding-top: 0;
    }

    .table-row:not(.table-header) {
      padding-bottom: 16px;
    }

    .table-cell.name,
    .table-cell.city {
      flex-basis: 72%;
    }

    .table-cell.date {
      flex-basis: 28%;
    }
  }
}

@media (min-width: 1281px) {
  .cat-shows-table {
    .table-header {
      .table-cell.name {
        .city {
          display: none;
        }
      }

      .table-cell.city,
      .table-cell.date {
        display: block;
      }
    }

    .table-cell.name {
      flex-basis: 50%;
    }

    .table-cell.city,
    .table-cell.date {
      flex-basis: 25%;
    }

    .table-row:not(.table-header) .table-cell.city {
      padding-top: 16px;
    }
  }
}

</style>
