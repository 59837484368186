<template>
  <div class="legal-docs-list">
    <ul class="legal-docs">
      <li class="legal-doc ">
        <a href="/legal_docs/terms-of-use.pdf" target="_blank">
          Пользовательское соглашение
        </a>
      </li>
      <li class="legal-doc ">
        <a href="/legal_docs/privacy-policy.pdf" target="_blank">
          Политика конфиденциальности
        </a>
      </li>
      <li class="legal-doc ">
        <a href="/legal_docs/cookie-policy.pdf" target="_blank">
          Правила использования файлов cookie
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'LegalDocsList',
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.legal-docs-list {
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  li {
    display: block;
    margin-top: 20px;

    &:before {
      display: none;
    }
  }

  .legal-doc a {
    position: relative;
    padding-left: 35px;
    padding-bottom: 0;

    &:before{
      content: "";
      background-image: url('./images/pdf_icon.svg');
      background-size: 30px 30px;
      width: 30px;
      height: 30px;
      display: inline-block;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
  }
}
</style>
