 <template>
  <div class="items-list">
    <a class="item"
         v-for="item in items"
         :key="item.id"
         :href="`${mainTopCatUrl}/callboard/pets/${item.id}`"
         target="_blank">
      <div class="item__img">
        <img :src="item.photo_src"/>
      </div>
      <dot class="item__title" :msg="formattedTitle(item.cat)" :line="2"></dot>
      <div class="item__price"
           v-if="item.currency">
        {{formattedPrice(item.price, item.currency.shortcut)}}
      </div>
      <dot class="item__text" :msg="item.text" :line="5"></dot>
    </a>
  </div>
</template>

<script>
import { GENDERS, TOP_CAT_URL } from '@/constants';
import dot from 'vue-text-dot';
import pluralize from '@/helpers';

export default {
  name: 'KittensList',

  props: ['items'],

  components: {
    dot,
  },

  data() {
    return {
      defaultAvatarFolder: 'default_avatars',
      mainTopCatUrl: TOP_CAT_URL,
      photosTopCatUrl: `${TOP_CAT_URL}/uploaded/callboard_images/`,
      flagsTopCatUrl: `${TOP_CAT_URL}/styles/public/images/icons/countries_flags/`,
    };
  },

  methods: {
    formattedTitle(cat) {
      const name = cat.name ? `${cat.name}, ` : '';
      const breed = cat.breed ? `${cat.breed.shortcut}, ` : '';
      const color = cat.color ? `${cat.color.code}, ` : '';
      const age = this.formattedAge(cat.age);
      const gender = GENDERS[cat.gender];
      return `${name}${gender}, ${breed}${color}${age}`;
    },

    formattedAge(age) {
      const [year, month] = age.split('.');
      let yearText = '';
      let monthText = '';

      if (year > 0) {
        yearText = `${year} ${pluralize(year, ['год', 'года', 'лет'])}`;
      }

      if (month > 0) {
        monthText = `${month} ${pluralize(month, ['месяц', 'месяца', 'месяцев'])}`;
      }

      return `${yearText} ${monthText}`;
    },

    formattedPrice(price, currency) {
      return `${(+price).toLocaleString()} ${currency}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/palette.scss';

  .items-list {
    margin-bottom: 23px;
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    width: 190px;
    margin-right: 24px;
    margin-bottom: 22px;
    font-size: 16px;
    font-weight: normal;
    display: block;
    text-decoration: none;
    color: black;
    text-decoration: none;

    &__img {
      width: 100%;
      height: 190px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }

    &__title {
      margin: 22px 0 8px;
      line-height: 20px;
      max-height: 38px;
      overflow: hidden;
      padding-right: 1rem;
      position: relative;
      text-overflow: ellipsis;
    }

    &__price {
      color: $brown;
      margin-bottom: 4px;
    }

    &__text {
      font-weight: lighter;
      font-size: 14px;
      line-height: 18px;
      overflow: hidden;
      padding-right: 1rem;
      position: relative;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: 1600px) {
    .items-list {
      margin-top: 27px;
    }

    .item {
      width: 240px;
      margin-right: 40px;
      margin-bottom: 40px;

      &__img {
        height: 240px;
      }

      &__title {
        margin: 25px 0 15px;
        line-height: 20px;
        font-size: 16px
      }

      &__price {
        margin-left: 1px;
        margin-bottom: 10px;
        word-spacing: 3px;
      }
    }
  }

  @media (max-width: 1024px) {
    .items-list {
      justify-content: space-between;
    }

    .item {
      margin-right: 0;
      margin-bottom: 16px;
      width: 130px;
      font-size: 14px;
      line-height: 18px;

      &__img {
        height: 130px;
      }

      &__title {
        margin-top: 11px;
        line-height: 18px;
        font-size: 14px
      }

      &__price {
        margin: 6px 0 8px;
      }

      &__text {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
</style>
