<template>
  <div class="app-container app-container--md app-layout app-background">
    <div class="app-container app-container--bg">
      <div class="app-container app-container--md">
        <div class="app-sidebar app-container--sm">
          <div class="fixed-sidebar scroll-area fixed-sidebar--mobile-no-fixed">
            <vue-scroll :ops="ops">
              <div class="fixed-sidebar__content">
                <div class="sidebar-wrapper">
                  <slot name="sidebar"></slot>
                </div>
                <footer class="footer">
                  <footer-contacts></footer-contacts>
                </footer>
              </div>
            </vue-scroll>
          </div>
        </div>
        <div class="app-content">
          <div class="app-container--sm content-container">
            <slot name="content"></slot>
          </div>

          <footer class="footer">
            <div class="app-container--sm footer-contacts-container">
              <footer-contacts></footer-contacts>
            </div>
            <div class="app-container--sm footer-partners-container">
              <footer-partners></footer-partners>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FooterContacts from '@/components/Footer/FooterContacts/FooterContacts.vue';
import FooterPartners from '@/components/Footer/FooterPartners/FooterPartners.vue';
import { SCROLL_OPTIONS } from '@/constants';

export default {
  name: 'BaseLayout',

  data() {
    return {
      ops: SCROLL_OPTIONS,
    };
  },

  components: {
    'footer-contacts': FooterContacts,
    'footer-partners': FooterPartners,
  },
};
</script>

<style lang="scss">
@import '@/styles/palette.scss';
@import '@/styles/constants.scss';

footer {
  height: 260px;
}

.app-container.app-layout.app-background {
  flex: 1 0 auto;
}

.app-sidebar {
  display: flex;

  .fixed-sidebar {
    display: none;
    position: fixed;
    top: $header-height;
    bottom: 0;
    width: 403px;
    margin-right: 13px;
    overflow-y: hidden;
    overflow-x: hidden;

    &__content {
      width: 380px;
      flex-direction: column;
      display: flex;

      .sidebar-wrapper {
        flex: 1 0 auto;
      }

      .footer {
        flex: 0 0 180px;
      }
    }
  }
}

.app-content {
  justify-content: space-between;
  flex-direction: column;
  display: flex;

  footer {
    background: $footer-bg;
  }

  .content-container {
    margin-bottom: 0;
  }

  .footer-contacts-container {
    background: $brown;
    padding-top: 60px;
    padding-bottom: 24px;
  }

  .footer-partners-container {
    background: $footer-bg;
    padding: 40px 20px;
  }
}

@media (max-width: 1024px) {
  .app-sidebar {
    .fixed-sidebar {
      .sidebar-wrapper {
        margin-bottom: 0px;
      }

      &--mobile-no-fixed {
        display: block;
        position: initial;
        width: 100%;

        footer {
          display: none;
        }
      }
    }
  }

  .route-catteries .app-sidebar .fixed-sidebar__content {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .app-sidebar {
    .fixed-sidebar {
      display: flex;

      .sidebar-wrapper {
        margin-bottom: 60px;
      }

      &__content {
        display: flex;
        height: calc(100vh - 180px);
      }
    }

    footer {
      display: block;
    }
  }

  .app-content .footer-contacts-container {
    display: none;
  }

  .app-content .footer-partners-container {
    padding: 80px 30px 80px 60px;
  }

  .app-layout.app-background {
    background:
      linear-gradient(to right, #50252D 0%, #50252D 50%, #f3f3f3 50%, #f3f3f3 100%),
      linear-gradient(to right, #50252D 0%, #50252D 50%, white 50%, white 100%);
    background-size: 100% 260px, 100% 100%;
    background-position-y: bottom, top;
    background-repeat: no-repeat;
  }

  .app-sidebar footer {
    background: $brown;
  }
}

@media (min-width: 1600px) {
  .app-content .footer-partners-container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .route-pets .content-container,
  .route-catteries .content-container,
  .route-kittens .content-container,
  .route-cat-shows .content-container {
    padding: 0 0 0 80px;
  }

  .app-sidebar .fixed-sidebar {
    width: 483px;

    &__content {
      width: 460px;
    }
  }
}

@media (min-width: 1780px) {
  .app-sidebar .fixed-sidebar {
    width: 503px;

    &__content {
      width: 480px;
    }
  }
}
</style>
