<template>
  <base-layout>
    <template v-slot:sidebar class="contact-sidebar">
      <div class="contacts">
        <section-title title="Контакты" isInlineDivider="true"/>
        <div class="contacts-data">
          <p class="info">
            Клуб работает:
            <span class="info__text info__text--mode">по средам с 17:30 - 20:00</span>
          </p>
          <p class="info">Адрес: <span class="info__text">г. Пенза, ул. Суворова, 92 к1</span></p>
          <p class="info">Телефон:<span class="info__text"> +7-963-109-95-11</span></p>
          <p class="info">
            E-mail:
            <span class="info__text info__text--email">elitarclub-loginova@mail.ru</span>
          </p>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <section-title title="Как нас найти" isInlineDivider="true"/>
      <p>Вход справа со стороны «Красивой Мебели»</p>
      <iframe :src="src" class='yandex-map'>
      </iframe>
      <p>Войти в правую дверь</p>
      <div class="contacts-photo"><img src="./beautiful_mebel.png"/></div>
      <div class="requisites">
        <p>РЕКВИЗИТЫ</p>
        <p>
          Наименование: ОБЩЕСТВЕННАЯ ОРГАНИЗАЦИЯЛЮБИТЕЛЕЙ ЖИВОТНЫХ "ЭЛИТАР-КЛУБ" Г. ПЕНЗЫ
        </p>
        <p>Сокращенное наименование: ООЛЖ «ЭЛИТАР-КЛУБ»</p>
        <p>Адрес: г. Пенза, ул. Суворова 92 литера «б» этаж 4 кабинет 12</p>
        <p>ОГРН: 1035800007385</p>
        <p>ИНН: 5836114779</p>
        <p>КПП: 583401001</p>
        <p>Телефон: 8 (8412) 45 20 92</p>
        <p>Президент клуба: Рудакова Анна Анатольевна</p>
      </div>
    </template>
  </base-layout>
</template>

<script>
import SectionTitle from '@/components/SectionTitle/SectionTitle.vue';
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';

export default {
  name: 'Contacts',

  data() {
    return {
      src: 'https://yandex.ru/map-widget/v1/?um=constructor%3Ac4e56fd7d64fa267ada85c0a0d3ec79f769164df8e4f3eac7acb49d401808fe6&amp;source=constructor',
    };
  },

  components: {
    'base-layout': BaseLayout,
    'section-title': SectionTitle,
  },
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
