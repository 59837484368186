<template>
  <div class="filters-field"
       :tabindex="tabindex"
       v-click-outside="hide">
    <div class="filters-field__header"
         :class="{open}"
         @click="open = !open">
      {{ selected }}
    </div>
    <div class="filters-field__items" :class="{hidden: !open}">
      <div class="scroll-area scroll-area__filter">
        <vue-scroll :ops="ops">
          <div class="scroll-content">
            <div :class="['filters-field__item',
                          {'filters-field__item--selected': option === selected}]"
                v-for="(option, index) of options"
                :key="index"
                @click="selected=option; open=false; $emit('input', option)">
              {{ option }}
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { SCROLL_OPTIONS } from '@/constants';

export default {
  name: 'FiltersSelect',

  props: {
    options: {
      type: Array,
      required: true,
    },

    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },

    initialValue: {
      type: [String, Number],
    },
  },

  directives: {
    ClickOutside,
  },

  data() {
    const defaultSelected = this.options.length > 0 ? this.options[0] : null;

    return {
      selected: this.initialValue || defaultSelected,
      defaultSelected,
      open: false,
      ops: SCROLL_OPTIONS,
    };
  },

  created() {
    this.$globalBus.$on('resetFilters', () => {
      this.selected = this.defaultSelected;
    });
  },

  mounted() {
    this.$emit('input', this.selected);

    this.$globalBus.$on('resetFilters', () => {
      this.reset();
    });
  },

  methods: {
    hide() {
      this.open = false;
    },

    reset() {
      this.selected = this.options.length > 0 ? this.options[0] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/filters_field.scss';

.filters-field{
  &__header {
    &:not(.open):hover {
      background: rgba(144, 86, 97, 0.5);
      border: 1px solid $filter-active-bg;
    }
  }

  &__items {
    &.hidden {
      display: none;
    }
  }

  &__item {
    height: 38px;
    line-height: 38px;

    &:not(.filters-field__item--selected):hover{
      color: $light-red;
    }
  }
}

@media (min-width: 1281px) {
  .filters-field {
    &__header {
      padding: 0 20px;
    }
  }
}
</style>
