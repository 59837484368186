<template>
  <base-layout>
    <template v-slot:content>
      <div class="not-found">
        <div class="not-found__img">
          <img src="../../assets/404.svg"/>
        </div>
        <div class="not-found__title">
            <mark>ОЙ!</mark> Что-то пошло не так
        </div>
        <div class="not-found__comment">
          Запрашиваемая вами страница не найдена. Вы можете
          <router-link :to="{name: 'home'}" exact>
            вернуться на главную
          </router-link>
          или воспользоваться меню.
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';

export default {
  name: 'PageNotFound',

  components: {
    'base-layout': BaseLayout,
  },
};
</script>

<style lang="scss">
@import '~@/styles/not_found.scss';

.page-not-found-route {
  width: 100%;
}

.not-found {
  max-width: 488px;
}

@media (min-width: 480px) {
  .not-found {
    max-width: 272px;
  }
}

@media (min-width: 1281px) {
  .not-found {
    max-width: 368px;
  }
}
</style>
