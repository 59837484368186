<template>
  <div class="cats-list">
    <a class="cat-item"
         v-for="cat in cats"
         :key="cat.id"
         :href="`${mainTopCatUrl}/cats/${cat.id}`"
         target="_blank">
      <div class="cat-item__img">
        <img :src="getPhotoUrl(cat.photo_src)"/>
      </div>
      <div class="cat-item__name">
        <span class="cat-item__flag">
          <img :src="`${flagsTopCatUrl}/${cat.holder_country.code}.png`"/>
        </span>
        {{cat.escaped_title_name}}
        <span class="cat-item__rang">
          {{cat.breed.shortcut}}
        </span>
      </div>
    </a>
  </div>
</template>

<script>
import { TOP_CAT_URL } from '@/constants';

export default {
  name: 'CatsList',

  props: ['cats'],

  data() {
    return {
      defaultAvatarFolder: 'default_avatars',
      mainTopCatUrl: TOP_CAT_URL,
      photosTopCatUrl: `${TOP_CAT_URL}/uploaded/cat_images/`,
      flagsTopCatUrl: `${TOP_CAT_URL}/styles/public/images/icons/countries_flags/`,
    };
  },

  methods: {
    getPhotoUrl(photoSrc) {
      const url = photoSrc.indexOf(this.defaultAvatarFolder) > 0
        ? `${this.mainTopCatUrl}${photoSrc}`
        : photoSrc;
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/palette.scss';

  .cats-list {
    margin-bottom: 23px;
    display: flex;
    flex-wrap: wrap;
  }

  .cat-item {
    width: 190px;
    margin-right: 24px;
    margin-bottom: 22px;
    display: block;
    color: black;
    text-decoration: none;

    &__img {
      width: 100%;
      height: 190px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }

    &__name {
      margin-top: 15px;
      line-height: 22px;
      font-size: 16px;
    }

    &__rang {
      color: $red;
    }

    &__flag {
      width: 21px;
      height: 20px;
      padding: 2px;
    }
  }

  @media (min-width: 1600px) {
    .cats-list {
      margin-top: 27px;
    }

    .cat-item {
      width: 240px;
      margin-right: 40px;
      margin-bottom: 40px;

      &__img {
        height: 240px;
      }

      &__name {
        margin-top: 18px;
        font-size: 18px;
      }

      &__flag {
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }
    }
  }

  @media (max-width: 480px) {
    .cats-list {
      justify-content: space-between;
    }

    .cat-item {
      margin-right: 0;
      margin-bottom: 11px;
      width: 130px;

      &__img {
        height: 130px;
      }

      &__name {
        margin-top: 7px;
        font-size: 14px;
      }

      &__flag {
        width: 19px;
        height: 16px;
      }
    }
  }
</style>
