<template>
  <div id="app" :class="[{ 'app--no-scroll': noScroll }, `route-${$route.name}`]">
    <fixed-header>
      <header class="app-container app-background">
        <div class="app-container app-container--bg">
          <div class="app-container app-container--md">
            <div class="app-sidebar app-container--sm">
              <div class="contacts-logo-block">
                <router-link :to="{name: 'home'}" class="logo" exact></router-link>
                <div class="contacts">
                  <a href="tel:+7 (963) 109-95-11" class="phone" rel="nofollow">
                    +7 (963) 109-95-11
                  </a>
                  <div class="address">г. Пенза, ул. Суворова, 92 к1</div>
                </div>
              </div>
            </div>
            <div class="app-content app-container--sm">
              <main-menu></main-menu>
            </div>
          </div>
        </div>
      </header>
    </fixed-header>

    <router-view/>
    <cookies-use-modal/><scroll-top-button/>
    <notifications position="bottom right"/>
  </div>
</template>

<script>
import FixedHeader from 'vue-fixed-header';

import CookiesUseModal from '@/components/CookiesUseModal/CookiesUseModal.vue';
import MainMenu from '@/components/MainMenu/MainMenu.vue';
import ScrollTopButton from '@/components/ScrollTopButton/SrollTopButton.vue';

export default {
  name: 'app',

  data() {
    return {
      noScroll: false,
    };
  },

  components: {
    FixedHeader,
    'cookies-use-modal': CookiesUseModal,
    'main-menu': MainMenu,
    'scroll-top-button': ScrollTopButton,
  },

  created() {
    document.title = 'Общественная организация любителей животных Элитар';
    this.$globalBus.$on('menuWasOpened', this.setNoScroll);
    this.$globalBus.$on('sectionMenuWasOpened', this.setNoScroll);
  },

  methods: {
    setNoScroll(value) {
      this.noScroll = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles/palette.scss';
@import 'styles/constants.scss';

#app {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.app--no-scroll {
    position: fixed;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

header {
  position: relative;
  flex: 0 0 auto;

  &.#{$header-fixed-class} {
    height: $mobile-fixed-header-height;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    & + * {
      padding-top: $mobile-fixed-header-height;
    }

    .logo.router-link-active {
      display: none;
    }
  }

  .app-content {
    padding-bottom: 0;
    padding-top: 0;
  }

  .app-background {
    background: $brown;
  }

  .app-sidebar {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .contacts-logo-block {
    padding-top: 0;
    min-height: 22px;
    color: white;
    line-height: 0;
  }

  .logo {
    display: none;
    background-image: url('~@/assets/logo.png');
    background-repeat: no-repeat;
    width: 120px;
    height: 80px;
    background-size: 120px 80px;

    &.router-link-active {
      display: inline-block;
    }
  }

  .contacts {
    display: none;
  }

  .phone {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
  }

  .address {
    font-size: 15px;
    line-height: 8px;
    margin-top: 7px;
  }
}

.route-contacts header .logo {
  display: block;
}

header .app-content {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  #app.app--no-scroll {
    position: relative;
    overflow: initial;
  }

  header {
    height: $header-height;
    padding: 0;

    &.#{$header-fixed-class} {
      height: $header-height;

      & + * {
        padding-top: $header-height;
      }

      .logo.router-link-active {
        display: block;
      }
    }

    .app-sidebar {
      padding-top: 0;
      padding-bottom: 40px;
    }

    .contacts-logo-block {
      padding-top: 40px;
      display: flex;
      align-items: flex-end;
    }

    .logo {
      display: block;
      width: 150px;
      height: 100px;
      background-size: 150px 100px;
    }

    .contacts {
      display: block;
      padding-left: 20px;
    }
  }

  .app-content {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  header .app-content {
    padding-left: 60px;
    padding-right: 30px;
  }

  .app-background {
    background: linear-gradient(to right, $brown 0%, $brown 50%, white 50%, white 100%);
  }
}

@media (min-width: 1537px) {
  header .app-content {
    padding-right: 0;
  }
}

@media (min-width: 1600px) {
  header .app-content {
    padding-left: 80px;
  }
}
</style>
