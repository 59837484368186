import { jsonApi, instance } from './index';

const catShowsAPI = {
  get(filters) {
    return jsonApi.findAll('cat-show', {
      include: 'country,city',
      filter: filters,
    });
  },

  getMaxYear() {
    return instance.get('/api/cat-shows/get-max-year');
  },
};

export default catShowsAPI;
