<template>
  <div class="section-sidebar">
    <div class="toggle-sidebar-link" @click="toggleMenu">
      {{name}}
    </div>
    <div :class="['sidebar-content', {'sidebar-content--opened': isOpened}]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionSidebar',

  props: ['name'],

  data() {
    return {
      isOpened: false,
    };
  },

  watch: {
    $route() {
      if (this.isOpened) {
        this.isOpened = false;
        this.$globalBus.$emit('sectionMenuWasOpened', this.isOpened);
      }
    },
  },

  created() {
    this.$globalBus.$on('menuWasOpened', (isMenuOpened) => {
      if (isMenuOpened && this.isOpened) {
        this.isOpened = false;
      }
    });
  },

  methods: {
    toggleMenu() {
      this.isOpened = !this.isOpened;
      this.$globalBus.$emit('sectionMenuWasOpened', this.isOpened);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';
@import '~@/styles/constants.scss';

.section-sidebar {
  position: relative;

  .sidebar-content {
    display: none;

    &--opened {
      position: fixed;
      top: $mobile-fixed-header-height;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      z-index: 10;
      background: $brown;
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }

  .toggle-sidebar-link {
    border-bottom: 4px solid $red;

    font-weight: bold;
    color: $red;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;

    position: fixed;
    top: 24px;
    height: 46px;
    z-index: 10;
    left: 24px;
  }
}

@media (min-width: 1024px) {
  .section-sidebar {
    box-sizing: border-box;

    .toggle-sidebar-link {
      display: none;
    }

    .sidebar-content {
      display: block;

      &--opened {
        top: 0;
        position: relative;
        padding: 0;
        z-index: 0;
      }
    }
  }
}
</style>
