 <template>
  <base-layout>
    <template v-slot:sidebar class="kittens-sidebar">
      <section-sidebar name="Котята">
        <div class="filters">
          <div class="filters-menu">
            Продажа
          </div>
          <gender-filter :filterValue="filters.gender"
                         @input="$set(filters, 'gender', $event)"
                         @reset="$delete(filters, 'gender')">
          </gender-filter>
          <div class="filters-field-group">
            <label>Порода</label>
            <filters-live-search searchVocabulary="breed"
                                 ref="breedFilter"
                                  :initialValue="filters.breed_id"
                                  @input="$set(filters, 'breed_id', $event.id)"
                                  @reset="$delete(filters, 'breed_id')">
              <template v-slot:default="slotProps">
                <div class="name">{{ slotProps.item.shortcut }} - {{ slotProps.item.name }}</div>
              </template>
            </filters-live-search>
          </div>
          <div class="filters-field-group">
            <label>Страна</label>
            <filters-live-search searchVocabulary="country"
                                 ref="countryFilter"
                                 :initialValue="filters.country_id"
                                 @input="$set(filters, 'country_id', $event.id)"
                                 @reset="$delete(filters, 'country_id');
                                          delete filters.city_id">
            </filters-live-search>
          </div>
          <div class="filters-field-group">
            <label :class="{disabled: !filters.country_id}">Город</label>
            <filters-live-search searchVocabulary="city"
                                  ref="cityFilter"
                                  :searchParams="JSON.stringify({country_id: filters.country_id})"
                                  :disabled="!filters.country_id"
                                  :initialValue="filters.city_id"
                                  @input="filters.city_id = $event.id"
                                  @reset="delete filters.city_id">
              <template v-slot:default="slotProps">
                <div class="name">{{ slotProps.item.name }}</div>
              </template>
            </filters-live-search>
          </div>
          <div class="filters-button-bar">
            <button class="filters-btn filters-btn--reset"
                    @click="resetFilters">
              Сбросить
            </button>
            <button class="filters-btn filters-btn--search"
                    @click="applyFilters">
              Найти
            </button>
          </div>
        </div>
      </section-sidebar>
    </template>

    <template v-slot:content>
      <template v-if="kittens.length">
        <kittens-list :items="kittens"/>
        <infinite-loading :identifier="infiniteId" @infinite="loadKittens"></infinite-loading>
      </template>
      <spinner v-else-if="isLoading"/>
      <not-found v-else/>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import GenderFilter from '@/components/GenderFilter/GenderFilter.vue';
import FiltersLiveSearch from '@/components/FiltersLiveSearch/FiltersLiveSearch.vue';
import KittensList from '@/components/KittensList/KittensList.vue';
import kittensAPI from '@/api/kitten';
import SectionSidebar from '@/components/SectionSidebar/SectionSidebar.vue';
import Spinner from '@/components/Spinner/Spinner.vue';
import NotFound from '@/components/NotFound/NotFound.vue';
import { ERROR_MESSAGE } from '@/constants';
import { jsonApi } from '@/api/index';

export default {
  name: 'Kittens',

  components: {
    'base-layout': BaseLayout,
    'gender-filter': GenderFilter,
    'filters-live-search': FiltersLiveSearch,
    'kittens-list': KittensList,
    'section-sidebar': SectionSidebar,
    'not-found': NotFound,
    Spinner,
  },

  data() {
    const defaultFilters = { page: 1 };
    const queryParams = this.$route.query;

    return {
      total: 0,
      kittens: [],
      isLoading: false,
      filters: { ...defaultFilters, ...queryParams },
      defaultFilters,
      infiniteId: 1,
      countryId: null,
    };
  },

  async mounted() {
    await this.loadKittens();

    this.$refs.breedFilter.search();
    await this.$refs.countryFilter.search();
    if (this.filters.country_id) {
      this.$refs.cityFilter.search();
    }
  },

  methods: {
    resetFilters() {
      this.filters = { ...this.defaultFilters };
      this.reloadKittens();
      this.$globalBus.$emit('resetFilters');
    },

    applyFilters() {
      this.filters.page = 1;
      this.reloadKittens();
    },

    reloadKittens() {
      this.kittens = [];
      this.total = 0;
      this.infiniteId += 1;

      this.$router.push({ path: 'kittens', query: { ...this.filters, t: new Date().getTime() } });

      this.loadKittens();
    },

    async loadKittens($state) {
      let kittens = [];
      let total = 0;

      if (this.isLoading) {
        jsonApi.cancel();
      }

      if (this.kittens.length && this.kittens.length === this.total) {
        if ($state) {
          $state.complete();
        }
        return;
      }

      this.isLoading = true;

      try {
        ({ data: kittens, meta: { total } } = await kittensAPI.get(this.filters));
      } catch (errors) {
        if (errors.type !== 'cancel') {
          this.$notify({
            type: 'error',
            title: ERROR_MESSAGE,
          });

          this.isLoading = false;
        }

        return;
      }

      this.isLoading = false;
      this.total = total;

      this.filters.page = parseInt(this.filters.page) + 1;
      this.kittens = this.kittens.concat(kittens);

      if ($state) {
        this.kittens.length === total ? $state.complete() : $state.loaded();
      }
    },
  },
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
