<template>
  <div class="filters-field-group">
    <label>Пол</label>
    <filters-select :options="Object.values(genders)"
                    :initialValue = "genders[filterValue]"
                    @input="onChangeGender">
    </filters-select>
  </div>
</template>

<script>
import FiltersSelect from '@/components/FiltersSelect/FiltersSelect.vue';
import { GENDERS } from '@/constants';

export default {
  name: 'GendersFilter',

  components: {
    'filters-select': FiltersSelect,
  },

  props: ['filterValue'],

  data() {
    return {
      genders: GENDERS,
    };
  },

  methods: {
    onChangeGender(value) {
      const valueIndex = Object.values(this.genders).indexOf(value);
      const key = Object.keys(this.genders)[valueIndex];

      if (key !== '') {
        this.$emit('input', key);
      } else {
        this.$emit('reset');
      }
    },
  },
};
</script>
