export default function pluralize(itemsCount, rules) {
  let number = itemsCount;

  if (number >= 5 && number <= 20) {
    return rules[2];
  }

  number %= 10;

  if (number === 1) {
    return rules[0];
  }

  if (number >= 2 && number <= 4) {
    return rules[1];
  }

  return rules[2];
}
