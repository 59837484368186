<template>
  <div class="filters-field"
       v-click-outside="hide">
    <div class="filters-field__header"
         :class="{open, disabled}"
         @click="!disabled && ( open = !open )">
      <input type="text"
             v-model="searchQuery"
             placeholder="Начните печатать для поиска"
             v-debounce="search"
             :disabled="disabled">
    </div>
    <div class="filters-field__items" v-show="open && items.length">
      <div class="scroll-area scroll-area__filter">
        <vue-scroll :ops="ops">
          <div class="scroll-content">
            <div :class="['filters-field__item',
                        {'filters-field__item--selected': item === selected}]"
                v-for="item in items"
                :key="item.id"
                @click="selected=item; searchQuery=item.name; open=false; $emit('input', item)">
              <slot :item="item">{{ item.name }}</slot>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { ERROR_MESSAGE, SCROLL_OPTIONS } from '@/constants';

export default {
  name: 'FiltersLiveSearch',

  props: [
    'searchParams',
    'searchVocabulary',
    'disabled',
    'initialValue', // item id
  ],

  directives: {
    ClickOutside,
  },

  data() {
    return {
      open: false,
      items: [],
      selected: '',
      searchQuery: '',
      value: this.initialValue,
      ops: SCROLL_OPTIONS,
    };
  },

  created() {
    this.$globalBus.$on('resetFilters', () => {
      this.reset();
    });
  },

  watch: {
    searchParams() {
      this.reset();
      this.search();
    },

    disabled(isDisabled) {
      if (isDisabled) {
        this.reset();
      }
    },
  },

  methods: {
    hide() {
      this.open = false;
    },

    async search() {
      if (this.disabled) {
        return;
      }

      if (!this.searchQuery && this.selected) {
        this.selected = '';
        this.$emit('reset');
      }

      let query;

      if (this.searchQuery) {
        query = { name: this.searchQuery, ...query };
      }

      if (this.searchParams) {
        query = { ...query, ...JSON.parse(this.searchParams) };
      }

      if (this.value) {
        query = { id: this.value, ...query };
      }

      try {
        this.items = await this.$store.cache.dispatch(
          'vocabulary/getVocabulary',
          { vocabulary: this.searchVocabulary, query },
        );
      } catch (errors) {
        this.$notify({
          type: 'error',
          title: ERROR_MESSAGE,
        });
      }

      if (this.value) {
        this.value = null;
        this.selected = this.items.length && this.items[0];
        this.searchQuery = this.selected.name;
      }
    },

    reset() {
      this.items = [];
      this.selected = '';
      this.searchQuery = '';
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/filters_field.scss';

.filters-field {
  &__header {
    input {
      font-family: 'Circe', sans-serif;
      color: white;
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      user-select: none;
      padding-right: 32px;

      &::placeholder {
        color: $filter-active-bg;
      }
    }

    &:not(.open):not(.disabled):hover {
      background: rgba(144, 86, 97, 0.5);
      border: 1px solid $filter-active-bg;
    }

    &.disabled:after {
      border-top-color: $filter-active-bg;
    }

    &.disabled input {
      cursor: not-allowed;
    }
  }

  &__item {
    min-height: 38px;
    line-height: 24px;
    padding-top: 7px;
    padding-bottom: 7px;

    &:not(.filters-field__item--selected):hover{
      color: $light-red;
    }
  }
}
</style>
