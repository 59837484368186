import { jsonApi } from './index';

const vocabulariesAPI = {
  getVocabulary(params) {
    return jsonApi.findAll(params['vocabulary'], {
      filter: params['query'],
    });
  },
};

export default vocabulariesAPI;
