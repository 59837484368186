import { jsonApi } from './index';

const petsAPI = {
  get(filter) {
    return jsonApi.findAll('cat', {
      include: 'breed',
      filter,
    });
  },
};

export default petsAPI;
