<template>
  <div class="section-title" :class="{ 'section-title--with-inline-divider': isInlineDivider }">
    <div class="section-title-text" :id="id" v-html="title"></div>
    <div class="text-divider" :class="{ 'text-divider--inline': isInlineDivider }">
      <div class="text-divider__item"></div>
      <div class="text-divider__item"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',

  props: [
    'title',
    'id',
    'isInlineDivider',
  ],
};
</script>

<style lang="scss" scoped>
@import '~@/styles/palette.scss';

.section-title {
  .section-title-text {
    font-family: 'Playfair Display';
    font-size: 24px;
    line-height: 32px;
  }

  .text-divider {
    display: inline-flex;
    height: 50px;
    align-items: center;
    padding-left: 2px;

    &__item {
      margin-right: 20px;
      width: 7px;
      height: 7px;
      background: $red;
      transform: rotate(45deg);
    }
  }
}

@media (min-width: 1024px) {
  .section-title {
    .section-title-text {
      font-size: 32px;
      line-height: 48px;
    }

    &--with-inline-divider {
      display: flex;
      margin-bottom: 20px;

      .section-title-text {
        line-height: 51px;
      }
    }

    .text-divider {
      height: 75px;

      &--inline {
        margin-left: 32px;
        height: 60px;
      }

      &__item {
        width: 9px;
        height: 9px;
      }
    }
  }
}

@media (min-width: 1281px) {
  .section-title {
    .section-title-text {
      font-size: 40px;
      line-height: 56px;
    }

    &--with-inline-divider {
      display: flex;
      margin-bottom: 20px;

      .section-title-text {
        line-height: 51px;
      }
    }

    .text-divider {
      display: inline-flex;
      height: 75px;
      align-items: center;

      &--inline {
        height: 60px;
      }

      &__item {
        margin-right: 20px;
        width: 9px;
        height: 9px;
        background: $red;
        transform: rotate(45deg);
      }
    }
  }
}
</style>
