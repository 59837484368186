<template>
  <div class="modal-window-wrapper" v-if="isShow">
    <div class="modal-window">
      <div class="modal-window__content">
        <div class="modal-title">Использование файлов cookie</div>
        <div class="modal-text">
          <p>
            Мы используем cookie-файлы, необходимые для правильной работы нашего веб-сайта.
            Также наш сайт и сторонние компании Google и Яндекс используют аналитические
            cookie-файлы для анализа аудитории и её поведения. Подробно изучить
            <a href="/legal_docs/cookie-policy.pdf" target="_blank">
              правила использования файлов cookie
            </a>
            можно, нажав на кнопку «Больше информации».
          </p>
          <p>
            Если вы согласны с использованием всех cookie-файлов, нажмите «Принять все».
            Воспользуйтесь кнопкой «Принять необходимые», если вы возражаете против обработки
            аналитических cookie-файлов.
          </p>
        </div>
        <div class="button-bar">
          <button class="btn"
            @click="saveAnalyticCookies(true)"
          >
            Принять все
          </button>
          <button class="btn"
            @click="saveAnalyticCookies(false)"
          >
            Принять необходимые
          </button>
          <a href="/legal_docs/cookie-policy.pdf" class="btn btn--info" target="_blank">
            Больше Информации
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SAVE_ANALYSTIC_COOKIE } from '@/constants';

export default {
  name: 'CookiesUseModal',

  computed: {
    isShow() {
      return (
        this.show
        && this.$cookies.get(SAVE_ANALYSTIC_COOKIE) === null
      );
    },
  },

  data() {
    return {
      show: true,
    };
  },

  methods: {
    saveAnalyticCookies(value) {
      this.$cookies.set(SAVE_ANALYSTIC_COOKIE, value);
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/palette.scss';

.modal-window-wrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(55, 71, 79, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 99999;

  .modal-window {
    position: relative;
    padding: 80px;
    background-color: #fff;
    width: 100%;
    max-width: 1024px;
    min-height: 440px;
    color: #000;
    font-family: Circe;
    font-size: 16px;

    .modal-title {
      font-family: 'Playfair Display';
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }

  .button-bar {
    margin-top: 32px;

    .btn {
      width: 272px;
      height: 40px;
      margin-right: 24px;
      font-size: 16px;
      line-height: 23px;

      &--info {
        background: transparent;
        color: $red;
        margin-right: 0;
        padding: 8px 0;

        &:hover {
          color: $light-red;
        }

        &:active {
          color: $dark-red;
        }
      }
    }
  }
}

@media(max-width: 1039px) {
  .modal-window-wrapper {
    .modal-window {
      .modal-title {
        margin-bottom: 20px;
      }
    }

    .button-bar {
      margin-top: 20px;

      .btn {
        margin-top: 14px;
        font-size: 16px;
        display: block;
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .modal-window-wrapper {
    align-items: flex-start;

    .modal-window {
      overflow: hidden;
      overflow-y: scroll;
      padding: 32px 24px;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
    }
  }
}
</style>
