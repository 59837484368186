<template>
  <div class="cookies-form">
    <p>
      Для подтверждения согласия на использование тех или иных cookie-файлов,
      выберете один из предложенных вариантов:
    </p>
    <div class="cookies-checkbox">
      <input type="checkbox"
        id="necessary_cookies_use"
        class="custom-checkbox"
        :checked="true"
        :disabled="true"
      >
      <label for="necessary_cookies_use">
        <p class="cookies-checkbox__text">
          Согласие на использование необходимых Cookie-файлов
        </p>
      </label>
    </div>

    <div class="cookies-checkbox">
      <input type="checkbox"
        v-model="cookiesSetting"
        id="analytic_cookies_use"
        class="custom-checkbox"
      >
      <label for="analytic_cookies_use">
        <p class="cookies-checkbox__text">
          Согласие на использование аналитических Cookie-файлов
        </p>
      </label>
    </div>

    <div class="button-bar">
      <button class="btn btn--save" @click="onSubmit">
        Запомнить выбор
      </button>
    </div>
  </div>
</template>

<script>
import { SAVE_ANALYSTIC_COOKIE } from '@/constants';

export default {
  name: 'CookiesUseForm',

  data() {
    const cookieValue = this.$cookies.get(SAVE_ANALYSTIC_COOKIE);
    return {
      cookiesSetting: cookieValue ? JSON.parse(cookieValue) : false,
    };
  },

  methods: {
    onSubmit() {
      this.$cookies.set(SAVE_ANALYSTIC_COOKIE, this.cookiesSetting);
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/palette.scss';

.cookies-form {
  margin-top: 32px;

  .text {
    font-family: "Circe", sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: lighter;
  }

  .cookies-checkbox {
    font-family: 'Circe', sans-serif;
    font-size: 16px;
    display: block;
    cursor: pointer;
    width: 100%;
    line-height: 20px;
    font-weight: lighter;
    margin-top: 16px;

    &__text {
      margin-bottom: 0;
    }

    .custom-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:hover {
        cursor: pointer;
      }
    }

    .custom-checkbox+label {
      display: inline-flex;
      align-items: start;
      margin-bottom: 0px;

      &:hover {
        cursor: pointer;
      }
    }

    .custom-checkbox+label::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 20px;
      border: 1px solid #C9C9C9;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }

    .custom-checkbox:not(:checked)+label::before {
      border: 1px solid #C9C9C9;
      background-color: #fff;
    }

    .custom-checkbox:active+label::before {
      border: 1px solid $red;
      background-color: $red;
    }

    .custom-checkbox:checked+label::before {
      border: 1px solid $red;
      background-color: $red;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    .custom-checkbox:disabled+label::before {
      border: 1px solid #6C3B44;
      background-color: #6C3B44;
    }
  }

  .button-bar {
    margin-top: 32px;

    .btn--save {
      font-size: 20px;
      width: 280px;
      height: 60px;
    }
  }
}

@media (max-width: 1241px) {
  .cookies-form {
    .button-bar {
      .btn--save {
        width: 280px;
        height: 48px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .cookies-form {
    .cookies-checkbox {
      font-size: 14px;
      line-height: 18px;
    }

    .cookies-save {
      margin-top: 32px;
      padding: 13px 57px 12px 59px;
      font-size: 16px;
    }

    .button-bar {
      .btn--save {
        width: 272px;
      }
    }
  }
}
</style>
