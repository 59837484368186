<template>
  <div class="catteries-list">
    <a class="cattery-item"
         v-for="cattery in catteries"
         :key="cattery.id"
         :href="`${mainTopCatUrl}/catteries/${cattery.id}`"
         target="_blank">
      <div class="cattery-item__img">
        <img :src="getPhotoUrl(cattery.photo_src)"/>
      </div>
      <div class="cattery-item__data">
        <span class="cattery-item__name">{{cattery.name}}</span>
        ({{cattery.system.name}})
        <span class="cattery-item__breed"
              v-for="(breed, index) in cattery.breeds"
              :key="index">
            {{breed.shortcut}},
        </span>
      </div>
      <div class="cattery-item__city">
        {{cattery.city.name}}, {{cattery.city.country.name}}
      </div>
    </a>
  </div>
</template>

<script>
import { TOP_CAT_URL } from '@/constants';

export default {
  name: 'CatteriesList',

  props: ['catteries'],

  data() {
    return {
      mainTopCatUrl: TOP_CAT_URL,
      photosTopCatUrl: `${TOP_CAT_URL}/uploaded/cattery_images/`,
      photosTopCatDefaultUrl: `${TOP_CAT_URL}/styles/public/images/default_avatars/cattery.png`,
    };
  },

  methods: {
    getPhotoUrl(photoSrc) {
      const url = photoSrc
        ? `${photoSrc}`
        : `${this.photosTopCatDefaultUrl}`;
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/palette.scss';

  .catteries-list {
    margin-bottom: 23px;
    display: flex;
    flex-wrap: wrap;
  }

  .cattery-item {
    width: 190px;
    margin-right: 24px;
    margin-top: 22px;
    display: block;
    color: black;
    text-decoration: none;

    &__img {
      width: 100%;
      height: 190px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }

    &__data {
      margin-top: 15px;
      line-height: 22px;
      font-size: 16px;
    }

    &__name {
      font-weight: bold;
    }

    &__breed {
      color: $red;
    }

    &__city {
      font-weight: 200;
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (min-width: 1600px) {
    .catteries-list {
      margin-top: 27px;
    }

    .cattery-item {
      width: 240px;
      margin-right: 40px;
      margin-bottom: 40px;

      &__img {
        height: 240px;
      }

      &__data {
        margin-top: 18px;
        font-size: 18px;
      }

      &__city {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 480px) {
    .catteries-list {
      justify-content: space-between;
    }

    .cattery-item {
      margin-right: 0;
      margin-bottom: 11px;
      width: 130px;

      &__img {
        height: 130px;
      }

      &__data {
        margin-top: 7px;
        font-size: 14px;
      }

      &__city {
        height: 14px;
      }
    }
  }
</style>
