<template>
  <div class="spinner"></div>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style lang="scss" scoped>
  .spinner  {
    width: 100%;
    height: 10px;
    background-image: url('./images/spinner.gif');
    background-size: 62px 10px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-top: 20px;
  }
</style>
